






















import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: {
    MEditor: () => import("@/components/molecules/editor/m-editor.vue"),
  },

  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({
        name: "",
        template: "",
      }),
    },
  },

  setup() {
    return {};
  },
});
